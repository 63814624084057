<template>
  <v-app class="pay">
    <v-layout wrap justify-center>
      <v-flex xs12 text-center align-self-center>
        <vue-element-loading
          :active="appLoading"
          color="#30D5C8"
          spinner="bar-fade-scale"
        >
        </vue-element-loading>
        <span style="font-size: 18px">
          Please don't go back or refresh the page, while we redirect you
        </span>
      </v-flex>
    </v-layout>
  </v-app>
</template>
    <script>
import axios from "axios";
export default {
  props: ["paymentId"],
  data() {
    return {
      appLoading: true,
    };
  },
  created() {
    // this.paymentStatus();
  },
  methods: {
    paymentStatus() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const payload = urlParams.get("payload");
      const merchantCode = urlParams.get("merchant_code");
      const schemeCode = urlParams.get("scheme_code");
      axios({
        method: "GET",
        url: "/booking/pineLabs/response",
        params: {
          payload: payload,
          merchantcode: merchantCode,
          schemecode: schemeCode,
        },
      })
        .then((response) => {
          if (response.data.status) {
            var PaymentData = null;
            PaymentData = response.data;
            if (PaymentData.status === "SUCCESS") {
              location.href =
                "http://test.periyartigerreserve.org/SuccessPagePine";
            } else if (PaymentData.status === "ERROR") {
              location.href =
                "http://test.periyartigerreserve.org/FailurePagePine";
            }
          } else {
            setTimeout(() => {
              window.alert("Something Went Wrong. Please try again later.");
              this.$router.push("/Bookings");
            }, 5000);
          }
        })
        .catch(() => {
          console.log("error");
        });
    },
  },
};
</script>
    <style>
.pay {
  min-height: 100vh;
  min-width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  background-color: white;
  overflow-x: hidden;
}
</style>